interface LogFunction {
  (message?: unknown, ...optionalParams: unknown[]): void
}

interface LoggerResult {
  debug: LogFunction
  info: LogFunction
  warn: LogFunction
  error: LogFunction
}

const getLogger = (): LoggerResult => {
  // eslint-disable-next-line no-console
  const debug = console.debug.bind(console)
  // eslint-disable-next-line no-console
  const info = console.info.bind(console)
  // eslint-disable-next-line no-console
  const warn = console.warn.bind(console)
  // eslint-disable-next-line no-console
  const error = console.error.bind(console)

  return {
    debug,
    info,
    warn,
    error,
  }
}

export const Logger = getLogger()

