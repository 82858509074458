import { ScriptHTMLAttributes } from 'react'

const DATA_ATTRIBUTE_START = 'data-'

const isDataAttribute = (attribute: string) =>
  attribute.startsWith(DATA_ATTRIBUTE_START)

const parseDataAttribute = (attribute: string) =>
  attribute.split(DATA_ATTRIBUTE_START)[1]

export const fetchJsFromCDN = (
  src: string,
  key: string,
  props: ScriptHTMLAttributes<HTMLScriptElement> & {
    [key: `${typeof DATA_ATTRIBUTE_START}${string}`]: string
  }
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = src

    // Assign to the script every attribute element passed to the function
    Object.keys(props).forEach((keyProps) => {
      if (isDataAttribute(keyProps)) {
        const parsedAttribute = parseDataAttribute(keyProps)
        script.dataset[parsedAttribute] = props[keyProps]
      } else {
        script[keyProps] = props[keyProps]
      }
    })

    script.addEventListener('load', () => {
      resolve(window[key])
    })
    script.addEventListener('error', reject)
    document.body.appendChild(script)
  })
}

