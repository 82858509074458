import { InsuranceModule } from '@typesApp/WindowType'

const MAX_WAIT_TIME = 15000

interface ModuleWaitUtilResult {
  waitForModule: () => Promise<InsuranceModule>
}

/**
 * Wait for some time to allow the scripts to load
 */
const moduleWaitUtil = (): ModuleWaitUtilResult => {
  
  const waitForModule = (): Promise<InsuranceModule> => {
    const startTime = new Date().getTime()

    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        const elapsedTime = new Date().getTime() - startTime
        const module = getInsuranceModule()
        if (module) {
          clearInterval(interval)
          resolve(module)
        } else if(elapsedTime > MAX_WAIT_TIME) {
          clearInterval(interval)
          reject(new Error(`Insurance module did not intialize after ${MAX_WAIT_TIME}ms`))
        }
      }, 200)
    })
  }

  const getInsuranceModule = (): InsuranceModule => {
    return window.directBilling
  }

  return {
    waitForModule
  }
}

export const ModuleWaitUtil = moduleWaitUtil()