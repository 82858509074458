
import { InsuranceModule } from '@typesApp/WindowType'
import { Logger } from '@utils/logger'
import { fetchJsFromCDN } from './fetchFromCdn'
import { ModuleWaitUtil } from './moduleWaitUtil'

const INSURANCE_MODULE_NAME = 'directBilling'

interface CDNFetchParams {
  id: string
  key: string
}

interface ModuleLoadUtilResult {
  loadModule: (host: string) => Promise<InsuranceModule>
}

interface Resolver {
  resolve: (value: InsuranceModule | PromiseLike<InsuranceModule>) => void
  reject: (reason?: unknown) => void
}

declare const APP_VERSION

/**
 * Fetch the manifest from the host then load the required scripts
 */
const moduleLoadUtil = (): ModuleLoadUtilResult => {
  /**
   * Load all scripts from asset-manifest
   */
  const loadModule = (host: string): Promise<InsuranceModule> => {
    return new Promise((resolve, reject) => {

      // check if it is already loaded
      const module = getInsuranceModule()
      if (module) {
        resolve(module)
        return
      }

      // fetch manifest and load scripts
      const url = `${host}${host.endsWith('/') ? '' : '/'}asset-manifest.json`
      Logger.info(
        '========== direct billing bundle HOST: %s  v%s ==========',
        host,
        APP_VERSION,
      )

      fetch(url)
        .then(response => response.json())
        .then(manifest => {
          const entrypoints = manifest.entrypoints as string[]
          const stylesheets = entrypoints?.filter(name => name.indexOf('static/css') >= 0)
          loadStylesheets(host, stylesheets)

          const scripts = entrypoints?.filter(name => name.indexOf('static/js') >= 0)
          resolveModule(host, scripts, { resolve, reject })

          loadSvgSprites(host, manifest.files)
        })
        .catch(error => {
          reject(error)
          Logger.error('Error loading direct billing bundle', error)
        })
    })
  }

  const loadStylesheets = (host: string, stylesheets: string[]) => {
    if (!stylesheets || !stylesheets.length) return

    const idPrefix = 'directBilling-'
    const calls = stylesheets.map(stylesheet => {
      const index = stylesheet.lastIndexOf('/')
      const id = `${idPrefix}${stylesheet.substring(index + 1)}`

      const loaded = !!document.getElementById(id)
      return !loaded
        ?
        new Promise<void>((resolve) => {
          const link = document.createElement('link')
          link.id = id
          link.rel  = 'stylesheet'
          link.href = `${host}${host.endsWith('/') ? '' : '/'}${stylesheet}`
          document.head.appendChild(link)
          link.onload = function() {
            resolve()
          }
        })
        : null
    })

    const validCalls = calls.filter(c => c)
    if (!validCalls.length) return

    Promise.allSettled(validCalls).then(results => {
      const hasError = results.filter(r => r.status === 'rejected').length > 0
      if (hasError) {
        Logger.error('One or more stylesheet(s) failed to load')
      }
    })
  }

  const loadSvgSprites = (host: string, files: Record<string, string>) => {
    const svgFiles = Object.keys(files).filter(name => name.endsWith('.svg'))
    const hostname = host.endsWith('/') ? host.substring(0, host.length - 1) : host
    if (!svgFiles.length) return

    const calls = svgFiles.map(name => {
      const url = `${hostname}${files[name]}`
      const id = `directBilling-${name.substring(name.lastIndexOf('/') + 1)}`
      const loaded = !!document.getElementById(id)
      
      return !loaded ?
        fetch(url)
          .then(res => res.text())
          .then(svgText => {
            const svgContainer = Object.assign(document.createElement('div'), {
              innerHTML: svgText,
              id,
            })
            Object.assign(svgContainer.style, {
              position: 'absolute',
              width: 0,
              height: 0,
              overflow: 'hidden',
              pointerEvents: 'none',
            })
            document.body.append(svgContainer)
          })
        : null
    })

    Promise.allSettled(calls.filter(Boolean))
  }

  const resolveModule = (host: string, scripts: string[], resolver: Resolver) => {
    const calls = getFetchCalls(host, scripts)
    if (calls.length === 0) {
      waitForLoadedModule(resolver)
    } else {
      fetchAndLoadModule(calls, resolver)
    }
  }

  const getFetchCalls = (host: string, scripts: string[]): Promise<InsuranceModule>[] => {
    
    // create fetch calls to load scripts as needed
    const calls = scripts.map(script => {
      const params = getFetchParams(script)
      const url = `${host}${host.endsWith('/') ? '' : '/'}${script}`
      const loaded = !!document.getElementById(params.id)

      return !loaded ? fetchJsFromCDN(url, params.key, { id: params.id }) : null
    })

    return calls.filter(c => c) as Promise<InsuranceModule>[]
  }

  const getFetchParams = (script: string): CDNFetchParams => {
    const startIndex = script.lastIndexOf('/')
    const id = `${INSURANCE_MODULE_NAME}-${script.substring(startIndex + 1, script.indexOf('.'))}`
    const key = ''
    return { id, key }
  }

  const waitForLoadedModule = (resolver: Resolver) => {
    const { resolve, reject } = resolver

    const module = getInsuranceModule()
    if (module) {
      resolve(module)
    } else {
      // Give some time for bundles to load
      ModuleWaitUtil.waitForModule()
      .then(module => {
        module && resolve(module)
      })
      .catch((error) => {
        // one last check
        const module = getInsuranceModule()
        if (module) {
          resolve(module)
        } else {
          reject(error)
        }
      })
    }
  }

  const fetchAndLoadModule = (calls: Promise<InsuranceModule>[], resolver: Resolver) => {
    const { resolve, reject } = resolver

    Promise.allSettled(calls).then((results => {
      
      if (hasScriptLoadError(results)) {
        reject(new Error('One or more script(s) failed fetch from CDN'))
      }
      
      const module = getInsuranceModule()
      if (module) {
        resolve(module)
      } else {
        reject(new Error('Scripts loaded sucessfullly but insurance module not intialized'))
      }
    }))
  }

  const hasScriptLoadError = (results: PromiseSettledResult<InsuranceModule>[]): boolean => {
    return results.filter(r => r.status === 'rejected').length > 0
  }

  const getInsuranceModule = (): InsuranceModule => {
    return window.directBilling
  }

  return {
    loadModule
  }
}

export const ModuleLoadUtil = moduleLoadUtil()