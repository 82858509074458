import { InsuranceModule } from '@typesApp/WindowType'
import { ModuleLoadUtil } from './moduleLoadUtil'
import { ModuleWaitUtil } from './moduleWaitUtil'

/**
 * Util for consuming app to load our bundle from the given host
 * @deprecated bundles are automatically loaded
 */
const load = (host: string): Promise<InsuranceModule> => {
  // bundles already loaded (or may be loading) at this point
  return ModuleWaitUtil.waitForModule()
}

/**
 * Attach the load function to window global
 * @deprecated remove once references removed from N1
 */
window.directBillingLoader = {
  load,
}

// auto load bundles
const script = document.currentScript
if (script && script.getAttribute('src')?.endsWith('/static/js/loader.js')) {
  const host = script.getAttribute('src')?.split('/static')?.[0]
  if (host) {
    ModuleLoadUtil.loadModule(host)
  }
}
